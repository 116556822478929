import React from "react";
import { NavLink } from "react-router-dom";

const selectedStyle = {
    color:"white",
    backgroundColor: "#dee2e6"
}

export const MainMenu = ({ toggleTheme }) =>(
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <NavLink className="navbar-brand" to="/">Navbar</NavLink>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav">
      <li className="nav-item">
        <NavLink className="nav-link" exact to="/" activeStyle = { selectedStyle }>Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/about" activeStyle = { selectedStyle }>Facts</NavLink>
      </li>
    </ul>
    <button type="button" className="btn" onClick={e => toggleTheme()}>Toggle Theme</button>
  </div>
</nav>
);