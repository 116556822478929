import React from "react";
import Areas from './countryFacts/Areas';
import Population from './countryFacts/Population';
import Continent from './countryFacts/Continent';


export default class Services extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            initialData:this.props.test,
            exampleItems: this.props.test2,
            loading: false,
        }
    }

    render(){
        const { initialData } = this.state
        return(
            <div className="container">
                <Areas initialData={initialData}/>
                <Population initialData={initialData}/>
                <Continent initialData={initialData}/>
            </div>
        )
        
    }
}