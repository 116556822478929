import React, {Component} from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';


const mapStyles = {
    width: '400px',
    height: '200px',
    elementType: "geometry",
    stylers: [
      {
        "color": "#f5f5f5"
      }
    ]
    
  };
export class MapContainer extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
          lat:this.props.lat[0],
          lng:this.props.lat[1],
      }
    }
  
    displayMarkers = () => {

        return <Marker  position={{
         lat: this.state.lat,
         lng: this.state.lng
       }}
       onClick={() => console.log("You clicked me!")} />
   
    }
  
    render() {
      return (
          <Map
            google={this.props.google}
            zoom={6}
            
            style={mapStyles}
            initialCenter={{ lat: this.state.lat, lng: this.state.lng}}
          >
            {this.displayMarkers()}
          </Map>
      );
    }
  }

  export default GoogleApiWrapper({
    apiKey: 'AIzaSyBnr5rICfv45Mt2LrKp8gOJ7peAE7jbZ3g'
  })(MapContainer);