import React from "react";


export default class DetailInfo extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            initialData:this.props.test,
            match:this.props.param
        }
        console.log(this.state.match,"important")
    }

    componentWillReceiveProps(){
        var updatedList = this.state.initialData;
        const search = this.state.match;
        updatedList = updatedList.filter(function(item) {
            return item.demonym.toLowerCase().search(search.toLowerCase()) !== -1;
          });
        this.setState({ initialData:updatedList }) 
    }


    render(){
        const { initialData } = this.state

        return(
            <div className="card mt-5"> 
            
            <div className="card-body">
            <h5 className="card-title">{initialData[0].name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">Capital: {initialData[0].capital}</h6>

            </div>
            <img src={initialData[0].flag} className="card-img-top" alt="..."/>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Region: {initialData[0].region}</li>
                <li className="list-group-item">Subregion: {initialData[0].subregion}</li>
                <li className="list-group-item">population: {initialData[0].population.toLocaleString()}</li>
                <li className="list-group-item">Area: {initialData[0].area.toLocaleString()}</li>
                <li className="list-group-item">Timezone: {initialData[0].timezones[0].toLocaleString()}</li>
                <li className="list-group-item">Native name: {initialData[0].nativeName}</li>
            </ul>

        </div>
        )
    }
}
