import React, { Component } from "react";

const BasicContext = React.createContext();

class BasicProvider extends Component{
    constructor(props){
        super(props);
        this.state = {
            username: 'user',
            loading:false
          };
         
    }

componentWillMount(){
    this.setState({ loading: true });
    fetch("https://restcountries.eu/rest/v2/all")
        .then(response => response.json())
        .then(response => {
        this.setState({ exampleItems: response, loading: false,initialData: response });
        console.log(this.state);
        }); 
}
    
render(){
    const { loading } = this.state
    return(
        loading === false ?
        <BasicContext.Provider value={ this.state }>
            {this.props.children}
        </BasicContext.Provider>
        : <h1>Loading</h1>
    )
}
}

const BasicConsumer = BasicContext.Consumer;

export { BasicProvider, BasicConsumer };