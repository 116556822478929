import React from "react";

export default class ContinentCard extends React.Component{
   constructor(props){
       super(props);
       this.state = {
           initialData: this.props.initialData,
           isToggleOn: true,
       }
       this.handleClick = this.handleClick.bind(this)
   }
   handleClick(){
       this.setState(state => ({
           isToggleOn: !this.state.isToggleOn
       }))
   }
   render(){
       const {  initialData, isToggleOn } = this.state
       return(
           <div>
            <h4 className="pt-3 mb-3 border-top pl-3 pr-3" onClick={this.handleClick}>{this.props.name.toUpperCase()} 
            <span className="badge badge-primary badge-pill float-right">{initialData.length} : countries</span>
            </h4>
            
            <ul className={`list-group list-group-flush ${isToggleOn ? 'collapse' : 'collapse.show'}`}>
               {
                   initialData.map((item,index) => {
                    return <li key={index} className="list-group-item">
                    <div className="media">
                        <div className="media-body">
                            <h5 className="mt-0 mb-1">{item.name}</h5>
                        </div>
                        <img src={item.flag} width="30" className="ml-3" alt="..."/>
                    </div>
                    </li>
                   })
               }
               </ul>
            </div>
       )
   }
}