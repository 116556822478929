import React from "react";
import { compareValues } from '../../utils/filter'
import ContinentCard from './ContinentCard';

export default class Continent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            initialData : this.props.initialData
        }
    }
    componentWillMount(){
        const asia = []
        const europe = []
        const polar = []
        const africa = []
        const oceania = []
        const americas = []
        function filterByCoutinent(item){
            if(item.region === 'Asia'){
                asia.push(item)
            }
            if(item.region === 'Europe'){
                europe.push(item)
            }
            if(item.region === 'Polar'){
                polar.push(item)
            }
            if(item.region === 'Africa'){
                africa.push(item)
            }
            if(item.region === 'Oceania'){
                oceania.push(item)
            }
            if(item.region === 'Americas'){
                americas.push(item)
            }

        }
        this.state.initialData.sort(compareValues('region','desc')).map((item) => {
            return{
                name:item.name,
                region:item.region,
                flag:item.flag
            } 
        }).filter(filterByCoutinent)
        const countryInCon = {
            asia:asia,
            europe:europe,
            polar:polar,
            africa:africa,
            oceania:oceania,
            americas:americas
        }
        this.setState({ countryInCon:countryInCon })

    }

    render(){
        const { countryInCon } = this.state
        return(
            <div className="mb-5">
            <h2>Country in Continent:</h2>
                {
                    countryInCon ? Object.keys(countryInCon).map((key,index) => {
                       return <ContinentCard key={index} initialData={countryInCon[key]} name={key}  />
                    }) : <h1>Loading</h1>
                }
            </div>
        )
    }
}