import React, {Component} from "react";

import Modal from './Modal';
import MapContainer from './MapContainer';
import { CSSTransition } from "react-transition-group";

export default class Land extends Component {
    constructor(props){
        super(props)
        this.state = {
            nation:this.props.nation,
            showModal:false,
            mounted:false,
            delay:this.props.delay
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
    }
    componentDidMount(){
        this.setState({
            mounted: true
        })
    }
    componentWillUnmount(){
        this.setState({
            mounted: false
        })
    }
    componentWillReceiveProps(nextProps) {
    this.setState({ nation: nextProps.nation,mounted: true });  
    }
    handleShow(){
        this.setState({ showModal: true });
    }
    handleHide(){
        this.setState({ showModal:false });
    }


render(){
    const{ nation,delay } = this.state
    const time = delay * 200;
    const modal = this.state.showModal ? (
        <Modal>   
        <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Country Details</h5>
                <button onClick={this.handleHide} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
              
            </div>
            <div className="modal-body">
                <MapContainer lat={nation.latlng} />
                
                <p>{nation.name}</p>
                <p>Capita: {nation.capital}</p>
                <p>Region: {nation.region}</p>
                <p>Subregion: {nation.subregion}</p>
                <p>Population: {nation.population} people</p>
                <p>TopLevelDomain: {nation.topLevelDomain.map(domain => domain).join(", ")}</p>
                <p>Timezones: {nation.timezones[0]}</p>
                <p>Languages: {nation.languages.map(lang => lang.name + ` ( ${lang.nativeName} )`).join(", ")}</p>
                <p>Native name: {nation.nativeName}</p>
                <p>Translations:</p>
                <p>[es]: {nation.translations['es']}</p>
                <p>[ja]: {nation.translations['ja']}</p>
                <p>[fa]: {nation.translations['fa']}</p>
                <p>Currencies: {nation.currencies.map(curr => `${curr.name} - ${curr.code} (${curr.symbol})`)}</p>
                <p></p>

            </div>
            <div className="modal-footer">
                <button onClick={this.handleHide} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>
        </Modal>
    ) : null;
    
    return (
        <CSSTransition
        in={this.state.mounted}
        appear={true}
        unmountOnExit
        classNames="fade"
        timeout={time}
        >
        <div className="col-md-4"  style={{transitionDelay: `${time}ms`}} >
        <div className="card">
            
            <div className="card-body">
            <h5 className="card-title">{this.props.nation.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">Capital: {this.props.nation.capital}</h6>

            </div>
            <img src={this.props.nation.flag} className="card-img-top" alt="..."/>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Region: {this.props.nation.region}</li>
                <li className="list-group-item">Subregion: {this.props.nation.subregion}</li>
            </ul>
            <div className="card-body">
                <button type="button" className="btn btn-outline-primary" onClick={this.handleShow}>Show more</button>
                </div>
        </div>
        {modal}
        </div>
        </CSSTransition>
    )
}

}      