import React, {Component} from "react";

import Land from './Land';
import Pagination from './Pagination';


export default class CountryList extends Component{
    constructor(props){
        super(props)
        this.state = {
            initialData:this.props.test,
            exampleItems: this.props.test2,
            pageOfItems: [],
            loading: false,

        }

        this.onChangePage = this.onChangePage.bind(this);
        this.filterList = this.filterList.bind(this);
        this.filterContinents = this.filterContinents.bind(this);
        console.log(this.state);
    }


    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
        console.log(this.state);
      }
      filterList(event) {
        var updatedList = this.state.initialData;
        updatedList = updatedList.filter(function(item) {
          return item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({ exampleItems: updatedList });
      }
      filterContinents(event) {
        var updatedList = this.state.initialData;
          if(event.target.value.toLowerCase() === "all region"){
              console.log("test");
              this.setState({ exampleItems: updatedList });
          }else{
              updatedList = updatedList.filter(function(item) {
                return item.region.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
              });
              this.setState({ exampleItems: updatedList });
          }
      }


    render(){
        const { exampleItems, pageOfItems} = this.state
        return (
        <div className="container">
            <div className="row">
            <div className="col-sm-12">
            <form>
                <div className="form-group">
                    <label>Search for Country:</label>
                    <input type="email" className="form-control" placeholder="Search" onChange={this.filterList}/>
                </div>
                <div className="form-group">
                <label>Search by contynent:</label>
                    <select className="form-control" onChange={this.filterContinents}>
                        <option>All region</option>
                        <option>Polar</option>
                        <option>Africa</option>
                        <option>Europe</option>
                        <option>Asia</option>
                        <option>Americas</option>
                        <option>Oceania</option>
                    </select>
                </div>
            </form>
            </div>
            </div>
            <div className="row">
                    {
                        pageOfItems.map((item,index) => {
                        return <Land key={index} nation={item} delay={index}/>})
                    }
            </div>
        <Pagination
            items={exampleItems}
            onChangePage={this.onChangePage}
            />
        </div>
        )
    }
}