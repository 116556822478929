import React from "react";
import { MainMenu } from "./menu";
import CountryList from "./CountryList";
import Services from "./Services";
import DetailInfo from "./DetailInfo";
import { CSSTransition } from "react-transition-group";
import { BasicConsumer } from "../BasicContext";


class PageTemplate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mounted:false,
            theme:"light"
        }
        this.toggleTheme = this.toggleTheme.bind(this);
    }

    toggleTheme(){
        const theme = this.state.theme === "light" ? "dark" : "light";
        this.setState({ theme });
        document.documentElement.setAttribute("data-theme", theme);
    }

    componentDidMount(){
        this.setState({ mounted: true })
    }
    render(){
        
        return(

            <div className="Page">
                <MainMenu toggleTheme={this.toggleTheme} />
                <CSSTransition
                    in={this.state.mounted}
                    appear={true}
                    unmountOnExit
                    classNames="fade"
                    timeout={2000}
                    >
                {this.props.children}
                </CSSTransition>
            </div>
            
        )
    }
}

export const Home = () => (
    <PageTemplate>
       <BasicConsumer>{({ initialData,exampleItems }) => <CountryList test={initialData} test2={exampleItems} />}</BasicConsumer>
    </PageTemplate>
)

export const About = () => (
    <PageTemplate>
        <BasicConsumer>{({ initialData,exampleItems }) =>  <Services test={initialData} test2={exampleItems} />}</BasicConsumer>
    </PageTemplate>
)

//const UniqueIDHeader = ({ match }) => <h1>{match.params.id}</h1>

export const Detail = ({ match }) => (
    <PageTemplate>
        <BasicConsumer>{({ initialData }) =>{
            const search = match.params.id;
            return <div className="container">
                <DetailInfo test={initialData} param={search} />
                     
            </div>
        }   }</BasicConsumer>
    </PageTemplate>
)


