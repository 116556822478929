import React from 'react';
import ReactDOM from 'react-dom';
import './theme.css';
import './App.css';
import { HashRouter, Route } from "react-router-dom";

import { Home, About, Detail } from "./components/pages"

import * as serviceWorker from './serviceWorker';

import { BasicProvider } from './BasicContext';


ReactDOM.render(
    <HashRouter>
        <BasicProvider>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About}/>
            <Route exact path="/land/:id" component={Detail}/>
        </BasicProvider>
    </HashRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
