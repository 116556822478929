import React from "react";
import { compareValues } from "../../utils/filter";
import { Link } from 'react-router-dom';

export default class Areas extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            area:[],
            initialData : this.props.initialData,
        }
    }

    componentWillMount(){
        var updatedList = this.state.initialData;
        updatedList = updatedList.sort(compareValues('area','desc')).slice(0,16).map((item,index) => 
           {
               return {
                area:item.area,
                name:item.name,
                demonym:item.demonym
            } 
        }  
        )
        this.setState({ area:updatedList })
    }


    render(){
        const { area } = this.state
        return(
            <div className="mt-5 mb-5">
                <h2>Most areas Country on Earth:</h2>
                <ul className="list-group">
                {
                 area ?  area.map((item,index) => {
                     return <li className="list-group-item" key={index}>
                                <Link to={`/land/${item.demonym}`}>{item.name}</Link> area: {item.area.toLocaleString()}
                            </li>
                    }) : <h1>Loading</h1>}
                </ul>
            </div>
        )
    }
}
