import React from "react";
import { compareValues } from "../../utils/filter";


export default class Population extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            population:[],
            initialData: this.props.initialData
        }
    }

    componentDidMount(){
        var updatedList = this.state.initialData;
        updatedList = updatedList.sort(compareValues('population','desc')).slice(0,16).map((item) => 
        {
            return {
            population:item.population,
            name:item.name
            } 
        }  
     )
        this.setState({ population:updatedList })
    }

    render(){
        const { population } = this.state
        return(
           <div className="mt-5 mb-5">
                <h2>Most people in Country on Earth:</h2>
                <ul className="list-group">
                {
                    population.map((item,index) => {
                    return <li className="list-group-item" key={index}><b>{item.name}</b> population: {item.population.toLocaleString()}</li>
                }
                )}
                </ul>
           </div> 
        )
    }
}